<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <div class="mb-2">
            <h4 class="font-size-16 text-gray-6">{{title}}</h4>
          </div>
          <div class="mb-4">
            <a-table
              :columns="columns"
              :dataSource="data"
              :customRow="goToSingleUser"
              :pagination="pagination"
              id="test"
            >
            <div
              slot="filterDropdown"
              slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
              style="padding: 8px"
            >
              <a-input
                v-ant-ref="c => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                Search
              </a-button>
              <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
                Reset
              </a-button>
            </div>
            <template slot="filterIcon" slot-scope="filtered">
              <a-icon
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
            </template>

              <template slot="status" slot-scope="text">
                <span :class="text === 'active' ? 'text-success' : 'text-warning'" class="text-capitalize">{{removeUnderScore(text)}}</span>
              </template>

              <template slot="userType" slot-scope="text">
                <span class="text-capitalize">{{removeUnderScore(text)}}</span>
              </template>

              <template slot="registered" slot-scope="text">
                <span class="text-capitalize">{{registeredDate(text)}}</span>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { formatDistance, differenceInDays } from 'date-fns'
import { SearchOutlined } from '@ant-design/icons';
const columns = [
  {
    title: 'First Name',
    dataIndex: 'first_name',
    className: 'text-gray-6',
    scopedSlots: { 
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'firstName' 
    },
    onFilter: (value, record) =>
    {    
      if(record.first_name){
        return record.first_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    className: 'text-gray-6',
    scopedSlots: { 
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'lastName' 
    },
    onFilter: (value, record) =>
    {    
      if(record.last_name){
        return record.last_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    className: 'text-left text-gray-6',
    scopedSlots: { 
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'email' 
    },
    onFilter: (value, record) =>
    {    
      if(record.email){
        return record.email
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    className: 'text-left text-gray-6',
    scopedSlots: { 
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'phone' 
    },
    onFilter: (value, record) =>
    {    
      if(record.phone){
        return record.phone
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    },
  },
  {
    title: 'User Type',
    dataIndex: 'user_type',
    className: 'text-left text-gray-6 text-capitalize',
    scopedSlots: { 
      customRender: 'userType' 
    },
    filters: [
      { text: 'Tourist', value: 'tourist' },
      { text: 'Tour Sight', value: 'tour_sight' },
      { text: 'Tour Operator', value: 'tour_operator' },
      { text: 'Hotel and Accomodations', value: 'hotel_and_accommodations' },
      { text: 'Food and Drinks', value: 'food_and_drinks' },
      { text: 'Events', value: 'events' },
      { text: 'Transport', value: 'transport' },
    ],
    onFilter: (value, record) => record.user_type.indexOf(value) === 0,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    className: 'text-left text-gray-6',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Registered',
    dataIndex: 'created',
    className: 'text-left text-gray-6',
    scopedSlots: { customRender: 'registered' },
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.created) - new Date(b.created),
  },
]

export default {
  name: 'usersTable',
  data: function () {
    return {
      columns,
      data: [],
      selectedRowKeys: [],
      title: 'Users',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      pagination: {
        pageSize: 20
      },
    }
  },
  async mounted() {
    await this.getAllUsers()
  },
  methods: {
    ...mapActions('admin', {
      getUsers: 'GET_USERS',
    }),
    async getAllUsers() {
      this.data = await this.getUsers({})
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    goToSingleUser(record) {
      return {
        on: {
          click: event => {
            console.log(event, record)
            this.$router.push({ name: 'user', params: { user_id: record._id, user_type: record.user_type } })
          },
          mouseenter: (event) => {
            document.querySelector('tbody').style.cursor = 'pointer'
          },
        },
      }
    },
    registeredDate(date) {
      const result = formatDistance(
        new Date(date),
        new Date(),
        { includeSeconds: true, addSuffix: true },
      )

      return result
    },
    removeUnderScore(word) {
      const result = word.replace(/_/g," ");
      return result
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
